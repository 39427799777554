<template>
  <div class="">
    <div class="flex justify-between border-b pb-5 border-grey-neutral mb-5">
      <h1 class="font-bold text-neutral-500">Activity Details</h1>
      <p class="font-semibold text-neutral-500">{{ countStudentActivityHasFinished }}/{{ studentActivities.length }} Activity Items</p>
    </div>
    <ActivityTable :columns="columnsTable" :list="studentActivities">
      <template slot="table-row" slot-scope="{ entry, column }">
        <div v-if="column.label === 'Description'" v-html="entry[column.field]"></div>
        <div v-else-if="column.label === 'Activity Type'">{{ convertText(entry[column.field]) }}</div>
        <div v-else-if="column.label === 'Detail' || column.label === 'Submission'">
          <div v-if="entry.activityItemType === 'WEBINAR' || entry.activityItemType === 'OFFLINE_MEETING'">
            <p v-if="defineTime(entry, column.label)" class="w-28">
              {{ defineTime(entry, column.label).constructLine1 }} <br />
              {{ defineTime(entry, column.label).constructLine2 }}
            </p>
            <p v-else>-</p>
          </div>
          <div v-else>
            <a v-if="defineLink(entry, column.label)" :href="defineLink(entry, column.label)" target="_blank" class="text-yellow">Link</a>
            <p v-else>-</p>
          </div>
        </div>
        <div v-else-if="column.label === 'Start' || column.label === 'End'">{{ formatDate(entry[column.field]) }}</div>
        <div v-else-if="column.label === 'Score'">{{ `${entry.activityItemType === 'MANUAL_TASK' ? entry.itemDetail.score : entry[column.field] ? entry[column.field] : '-'}` }}</div>
        <div v-else>{{ `${entry[column.field] ? entry[column.field] : '-'}` }}</div>
      </template>
    </ActivityTable>
  </div>
</template>

<script>
import dayjs from 'dayjs'
export default {
  name: 'student-report-activities',
  props: {
    studentActivities: {
      type: Array,
      default: () => []
    }
  },
  components: {
    ActivityTable: () => import(/* webpackChunkName: "simple-table" */ '@/components/Table/SimpleTable')
  },
  computed: {
    columnsTable() {
      return [
        { label: 'Activity', field: 'activityTitle' },
        { label: 'Sub-Activity', field: 'title' },
        { label: 'Description', field: 'title' },
        { label: 'Activity Type', field: 'activityItemType' },
        { label: 'Detail', field: 'itemDetail' },
        { label: 'Start', field: 'studentStartedAt' },
        { label: 'End', field: 'studentEndedAt' },
        { label: 'Submission', field: 'itemDetail' },
        { label: 'Score', field: 'score' }
      ]
    },
    countStudentActivityHasFinished() {
      if (this.studentActivities.length === 0) return 0
      const countActivity = this.studentActivities.filter((activity) => activity.studentStartedAt && activity.studentEndedAt).length
      return countActivity
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return '-'
      return dayjs(date).format('DD MMMM YYYY HH:mm')
    },
    convertText(text) {
      // Split the input string by underscore ('_') or space (' ') to get individual words
      const words = text.split(/[_\s]+/)
      // Capitalize the first letter of each word and convert the rest of the letters to lowercase
      const convertedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      // Join the converted words with a space to form the converted string
      const convertedText = convertedWords.join(' ')
      return convertedText
    },
    defineLink(item, column) {
      if (item.activityItemType === 'OPEN_LINK' && column === 'Detail') return item.link
      if (item.activityItemType === 'MANUAL_TASK' && column === 'Submission') return item.itemDetail.linkUploadTask
      if (item.activityItemType === 'DOCUMENT' && column === 'Detail') return item.itemDetail.documentUrl
      return null
    },
    defineTime(item, column) {
      if (column !== 'Detail') return null
      const startTime = item?.startTime
      const endTime = item?.endTime
      let constructLine1 = null
      let constructLine2 = null
      if (dayjs(startTime).isSame(dayjs(endTime), 'date')) {
        constructLine1 = dayjs(item.startTime).format('DD MMMM YYYY')
        constructLine2 = `${dayjs(item.startTime).format('HH:mm')} - ${dayjs(item.endTime).format('HH:mm')}`
      } else {
        constructLine1 = `${dayjs(item.startTime).format('DD MMMM YYYY')} ${dayjs(item.startTime).format('HH:mm')} -`
        constructLine2 = `${dayjs(item.endTime).format('DD MMMM YYYY')} ${dayjs(item.endTime).format('HH:mm')}`
      }
      return { constructLine1, constructLine2 }
    }
  }
}
</script>

<style></style>