var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {}, [_c('div', {
    staticClass: "flex justify-between border-b pb-5 border-grey-neutral mb-5"
  }, [_c('h1', {
    staticClass: "font-bold text-neutral-500"
  }, [_vm._v("Activity Details")]), _c('p', {
    staticClass: "font-semibold text-neutral-500"
  }, [_vm._v(_vm._s(_vm.countStudentActivityHasFinished) + "/" + _vm._s(_vm.studentActivities.length) + " Activity Items")])]), _c('ActivityTable', {
    attrs: {
      "columns": _vm.columnsTable,
      "list": _vm.studentActivities
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(_ref) {
        var entry = _ref.entry,
            column = _ref.column;
        return [column.label === 'Description' ? _c('div', {
          domProps: {
            "innerHTML": _vm._s(entry[column.field])
          }
        }) : column.label === 'Activity Type' ? _c('div', [_vm._v(_vm._s(_vm.convertText(entry[column.field])))]) : column.label === 'Detail' || column.label === 'Submission' ? _c('div', [entry.activityItemType === 'WEBINAR' || entry.activityItemType === 'OFFLINE_MEETING' ? _c('div', [_vm.defineTime(entry, column.label) ? _c('p', {
          staticClass: "w-28"
        }, [_vm._v(" " + _vm._s(_vm.defineTime(entry, column.label).constructLine1) + " "), _c('br'), _vm._v(" " + _vm._s(_vm.defineTime(entry, column.label).constructLine2) + " ")]) : _c('p', [_vm._v("-")])]) : _c('div', [_vm.defineLink(entry, column.label) ? _c('a', {
          staticClass: "text-yellow",
          attrs: {
            "href": _vm.defineLink(entry, column.label),
            "target": "_blank"
          }
        }, [_vm._v("Link")]) : _c('p', [_vm._v("-")])])]) : column.label === 'Start' || column.label === 'End' ? _c('div', [_vm._v(_vm._s(_vm.formatDate(entry[column.field])))]) : column.label === 'Score' ? _c('div', [_vm._v(_vm._s("".concat(entry.activityItemType === 'MANUAL_TASK' ? entry.itemDetail.score : entry[column.field] ? entry[column.field] : '-')))]) : _c('div', [_vm._v(_vm._s("".concat(entry[column.field] ? entry[column.field] : '-')))])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }